.cs_site_header.cs_style1.cs_active_sticky {
  background-color: #fff;
}
.cs_site_header.cs_style1.cs_white_color.cs_active_sticky {
  background-color: #274760;
}
.cs_accent_bg .cs_social_links_wrap h2,
.cs_site_header.cs_style1.cs_white_color.cs_active_sticky .cs_toolbox {
  color: #fff;
}
.cs_contact_widget i {
  height: 20px;
  width: 20px;
  margin-top: 2px;
  color: #fff;
  padding: 4px;
}
.cs_video_open {
  cursor: pointer;
}
.cs_hero.cs_style_1 {
  .cs_text_btn_2,
  .cs_hero_title,
  .cs_hero_subtitle {
    position: relative;
    z-index: 1;
  }

  .cs_hero_text {
    position: initial;
    z-index: initial;
  }
}
.cs_accordian_body_wrap {
  overflow: hidden;
  transition: all 0.4s ease;
}
.cs_tabs.cs_style1 .cs_tab_links .cs_tab_link_in {
  cursor: pointer;
}
.cs_rating {
  i {
    display: flex;
  }
}
.cs_shape_wrap .cs_shape_2 {
  top: -3%;
}
.cs_cs_slider_navigation_1 {
  .cs_slider_next,
  .cs_slider_prev {
    transition: all 0.4s ease;
    cursor: pointer;
    border-radius: 10px;
    padding: 5px;
    height: 60px;
    transform: translateY(-50%);
    background-color: #fff;
    width: 70px;
    box-shadow: 0px 4px 21px 1px rgba(48, 123, 196, 0.1);
    position: absolute;
    top: 50%;
    display: flex !important;
    z-index: 1;
    @media (min-width: 991px) {
      opacity: 0;
    }
  }
  .cs_slider_next {
    right: -35px;
  }
  .cs_slider_prev {
    left: -35px;
  }
  &:hover {
    .cs_slider_next,
    .cs_slider_prev {
      opacity: 1;
    }
  }
}
.cs_cs_slider_navigation_2 {
  .cs_slider_next,
  .cs_slider_prev {
    height: 40px;
    width: 40px;
    border: 2px solid $accent;
    border-radius: 50%;
    padding: 8px;
    transition: all 0.4s ease;
    cursor: pointer;
    position: absolute;
    display: flex !important;
    bottom: 0;
  }
  .cs_slider_next {
    right: 0;
  }
  .cs_slider_prev {
    right: 52px;
  }
}
.cs_cs_slider_navigation_3 {
  .cs_slider_next,
  .cs_slider_prev {
    width: 90px;
    height: 70px;
    background-color: #fff;
    border-radius: 10px;
    padding: 5px;
    transition: all 0.4s ease;
    cursor: pointer;
    display: flex !important;
    position: absolute;
    top: -213px;
    @media (max-width: 1700px) {
      width: 80px;
      height: 60px;
      top: -188px;
    }
    @media (max-width: 991px) {
      display: none !important;
    }
    img {
      transition: inherit;
    }
    &:hover {
      background-color: $accent;
      img {
        filter: brightness(0) invert(1);
      }
    }
  }
  .cs_slider_next {
    right: 0;
  }
  .cs_slider_prev {
    right: 115px;
    @media (max-width: 1700px) {
      right: 100px;
    }
  }
  .slick-dots {
    display: none !important;
    @media (max-width: 991px) {
      display: flex !important;
    }
  }
}
.cs_related_doctor {
  padding-bottom: 90px;
  @media (max-width: 400px) {
    padding-bottom: 75px;
  }
  .slick-dots {
    bottom: 15px;
  }
}
.cs_gallery_grid_1 {
  display: grid;
  grid-gap: 25px;
  grid-template-columns: repeat(3, 1fr);
  @media (max-width: 991px) {
    grid-template-columns: repeat(2, 1fr);
    .cs_grid_item {
      &:nth-child(2) {
        display: none;
      }
    }
  }
  @media (max-width: 767px) {
    display: flex;
    flex-direction: column;
    gap: 25px;
  }
  .cs_section_heading.cs_style_1 {
    min-height: 263px;
    @media (max-width: 1700px) {
      min-height: 166px;
    }
    @media (max-width: 991px) {
      min-height: initial;
    }
  }
  .cs_grid_item {
    &:first-child {
      grid-column: span 2;
    }
    @media (min-width: 992px) {
      &:nth-child(6) {
        grid-column: span 2;
      }
    }

    &:nth-child(5) {
      .cs_portfolio.cs_style_1 {
        height: 767px;
        margin-top: -338px;
        @media (max-width: 1700px) {
          height: 590px;
          margin-top: -240px;
        }
        @media (max-width: 991px) {
          margin-top: 0;
          height: 400px;
        }
      }
      @media (max-width: 991px) {
        grid-column: span 2;
      }
      @media (max-width: 767px) {
        grid-column: initial;
      }
    }
  }
  @media (max-width: 1700px) {
    .cs_portfolio.cs_style_1 {
      height: 350px;
    }
  }
}
.cs_gallery_grid_2 {
  display: grid;
  grid-gap: 25px;
  grid-template-columns: repeat(3, 1fr);
  @media (max-width: 991px) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media (max-width: 767px) {
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 25px 0px;
  }

  .cs_grid_item {
    @media (min-width: 768px) {
      &:first-child {
        grid-column: span 2;
      }
    }

    @media (min-width: 992px) {
      &:nth-child(7) {
        grid-column: span 2;
      }
    }
  }
  @media (max-width: 1700px) {
    .cs_portfolio.cs_style_1 {
      height: 350px;
    }
  }
  @media (max-width: 420px) {
    .cs_portfolio.cs_style_1 {
      height: 300px;
    }
  }
}
.cs_portfolio.cs_style_1 .cs_portfolio_img > div > img {
  cursor: zoom-in;
  height: 100%;
  left: 0;
  -o-object-fit: cover;
  object-fit: cover;
  opacity: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 5;
}
.cs_team_grid {
  display: grid;
  grid-gap: 50px 25px;
  grid-template-columns: repeat(3, 1fr);
  @media (max-width: 991px) {
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 40px 25px;
  }
  @media (max-width: 767px) {
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 40px 25px;
  }
  &.cs_list_view_wrap {
    grid-template-columns: repeat(1, 1fr);
  }
  &.cs_grid_view_wrap {
    .cs_team.cs_style_1.cs_type_2 {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
  }
}
.cs_isotop_filter.cs_style1 {
  text-transform: capitalize;
}
.cs_pagination_box {
  .cs_pagination_item,
  .cs_pagination_arrow {
    border: none;
    &:hover {
      color: $accent;
    }
  }
  .cs_pagination_arrow {
    background-color: transparent;
  }
}
.form-check .form-check-input {
  border-color: #636363;
}
.cs_related_doctor .slick-dots {
  display: flex !important;
}
.react-datepicker-wrapper {
  display: block;
}
.cs_blog_grid_1 {
  display: grid;
  grid-gap: 20px 130px;
  grid-template-columns: repeat(4, 1fr);
  @media (max-width: 1700px) {
    grid-gap: 20px 80px;
  }
  @media (max-width: 1400px) {
    grid-gap: 20px;
  }
  @media (max-width: 991px) {
    grid-template-columns: repeat(1, 1fr);
  }
  @media (min-width: 992px) {
    > * {
      grid-column: span 2;
      &:first-child {
        grid-row: span 3;
      }
    }
  }
}
.cs_error {
  padding: 80px 0;
  min-height: 100vh;
}
.cs_nav .cs_nav_list .cs_munu_dropdown_toggle.active + ul {
  display: block;
}
input[type='date']::-webkit-inner-spin-button,
input[type='date']::-webkit-calendar-picker-indicator,
input[type='time']::-webkit-inner-spin-button,
input[type='time']::-webkit-calendar-picker-indicator {
  display: none;
  -webkit-appearance: none;
}

// ** Custom SCSS
/*--------------------------------------------------------------
# Main Slider Three
--------------------------------------------------------------*/
:root {
  --sonchoy-gray: #767676;
  --sonchoy-gray-rgb: 118, 118, 118;
  --sonchoy-white: #ffffff;
  --sonchoy-white-rgb: 255, 255, 255;
  --sonchoy-base: #6222cc;
  --sonchoy-base-rgb: 98, 34, 204;
  --sonchoy-black: #040b1e;
  --sonchoy-black-rgb: 4, 11, 30;
  --sonchoy-primary: #f4f4f4;
  --sonchoy-primary-rgb: 244, 244, 244;
  --sonchoy-bdr-color: #dedede;
  --sonchoy-bdr-rgb: 222, 222, 222;
}

.main-slider-three {
  position: relative;
  display: block;
  background-color: #eeeef5;
  z-index: 10;
}

.main-slider-three .item {
  background-color: #eeeef5;
  position: relative;
  padding-top: 172px;
  padding-bottom: 185px;
  z-index: 10;
}

.main-slider-three__img {
  position: absolute;
  top: -75.5555px;
  right: 55.5555px;
  transform: translateX(500px);
  opacity: 0;
  z-index: 1;
  width: 55.5555%;
  @media (max-width: 767px) {
    width: 75.5555%;
    right: 0.5555%;
  }
}

.main-slider-three__img img {
  width: auto !important;
}

.swiper-slide-active .main-slider-three__img {
  opacity: 1;
  transform: translateX(0px);
  transition: all 1000ms ease;
  transition-delay: 500ms;
  z-index: 1;
}

.main-slider-three__shape-2 {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1;
}

.main-slider-three__shape-2 img {
  width: auto !important;
}

.main-slider-three__shape-3 {
  position: absolute;
  bottom: 0px;
  right: 0px;
  z-index: 1;
}

.main-slider-three__shape-3 img {
  width: auto !important;
}

.main-slider-three__shape-4 {
  position: absolute;
  left: -48px;
  top: 57px;
  border-radius: 50%;
  background-color: rgb(198, 208, 249);
  width: 137px;
  height: 137px;
}

.main-slider-three__shape-5 {
  position: absolute;
  left: 126px;
  top: 160px;
  border-radius: 50%;
  background-color: rgb(255, 218, 86);
  width: 24px;
  height: 24px;
}

.main-slider-three__content {
  position: relative;
  display: block;
  z-index: 10;
}

.main-slider-three__title {
  position: relative;
  display: block;
  font-size: 80px;
  color: var(--sonchoy-black);
  font-weight: 500;
  line-height: 80px;
  text-transform: capitalize;
  letter-spacing: -0.02em;
  margin-bottom: 18px;
  opacity: 0;
  visibility: hidden;
  -webkit-transform: translateY(-120px);
  transform: translateY(-120px);
  -webkit-transition-delay: 1000ms;
  transition-delay: 1000ms;
  -webkit-transition: opacity 2000ms ease, -webkit-transform 2000ms ease;
  transition: opacity 2000ms ease, -webkit-transform 2000ms ease;
  transition: transform 2000ms ease, opacity 2000ms ease;
  transition: transform 2000ms ease, opacity 2000ms ease,
    -webkit-transform 2000ms ease;
  @media (max-width: 767px) {
    font-size: 40px;
    line-height: 60px;
  }
}

.main-slider-three__color-text {
  font-weight: 700;
  color: var(--sonchoy-base);
  position: relative;
  display: inline-block;
  @media (max-width: 767px) {
    font-size: 40px;
  }
}

.main-slider-three__color-text:before {
  content: '';
  position: absolute;
  bottom: 0;
  right: 10px;
  height: 9px;
  width: 187px;
  background-image: url('../../public/images/shapes/main-slider-three-shape-1.png');
  background-repeat: no-repeat;
}

.main-slider-three__color-text-two {
  font-weight: 700;
}

.main-slider-three__text {
  position: relative;
  display: block;
  opacity: 0;
  visibility: hidden;
  -webkit-transform: translateY(120px);
  transform: translateY(120px);
  -webkit-transition-delay: 1000ms;
  transition-delay: 1000ms;
  -webkit-transition: opacity 2000ms ease, -webkit-transform 2000ms ease;
  transition: opacity 2000ms ease, -webkit-transform 2000ms ease;
  transition: transform 2000ms ease, opacity 2000ms ease;
  transition: transform 2000ms ease, opacity 2000ms ease,
    -webkit-transform 2000ms ease;
}

.swiper-slide-active .main-slider-three__text {
  visibility: visible;
  opacity: 1;
  -webkit-transform: translateY(0) translateX(0);
  transform: translateY(0) translateX(0);
}

.main-slider-three__btn-and-text-box {
  position: relative;
  display: flex;
  align-items: center;
  margin-top: 46px;
  opacity: 0;
  visibility: hidden;
  -webkit-transform: translateY(120px);
  transform: translateY(120px);
  -webkit-transition-delay: 1000ms;
  transition-delay: 1000ms;
  -webkit-transition: opacity 2000ms ease, -webkit-transform 2000ms ease;
  transition: opacity 2000ms ease, -webkit-transform 2000ms ease;
  transition: transform 2000ms ease, opacity 2000ms ease;
  transition: transform 2000ms ease, opacity 2000ms ease,
    -webkit-transform 2000ms ease;
}

.main-slider-three__text-box {
  color: var(--sonchoy-base);
  margin-left: 30px;
}

.main-slider-three__text-box span {
  font-size: 20px;
  font-weight: 700;
}

.swiper-slide-active .main-slider-three__title,
.swiper-slide-active .main-slider-three__btn-and-text-box {
  visibility: visible;
  opacity: 1;
  -webkit-transform: translateY(0) translateX(0);
  transform: translateY(0) translateX(0);
}
