/*--------------------------------------------------------------
7. Header
----------------------------------------------------------------*/

.cs_site_header {
   position: relative;
   z-index: 101;
}

.cs_site-branding {
   display: inline-block;
   max-width: 180px;
}
.cs_site_header {
   position: absolute;
   width: 100%;
   left: 0;
   top: 0px;
   transition: all 0.4s ease;
}
.cs_toolbox {
   display: flex;
   gap: 45px;
   .cs_toolbox_btn {
      border: none;
      background-color: transparent;
      display: flex;
      padding: 0;
      cursor: pointer;
      transition: all 0.4s ease;
      &:hover {
         color: $accent;
      }
   }
}
.cs_site_header.cs_style1 {
   .cs_main_header_in,
   .cs_top_header_in {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      justify-content: space-between;
      height: 100px;
      position: relative;
   }
   .cs_main_header_right {
      display: flex;
      align-items: center;
      height: 100%;
   }
   &.cs_white_color {
      .cs_toolbox {
         color: $primary;
      }

      @media (max-width: 1199px) {
         .cs_nav .cs_nav_list {
            color: #fff;
            background-color: $primary;
         }
         .cs_menu_toggle.cs_toggle_active {
            color: #fff;
         }
      }
   }
   &.cs_gescout_sticky {
      &.cs_white_color {
         .cs_toolbox {
            color: #fff;
         }
         .cs_menu_toggle:not(.cs_toggle_active) span {
            color: #fff;
         }
      }
   }
   &.cs_color_1 {
      .cs_toolbox_btn {
         color: #fff;
      }
      .cs_menu_toggle {
         color: #fff;
      }
   }
}

.cs_site_header.cs_style1 {
   &.cs_sticky_active {
      background-color: $white;
      box-shadow: 0 10px 10px -10px rgba(33, 43, 53, 0.1);
   }

   .cs_action_box {
      display: flex;
      align-items: center;
      .cs_action_value {
         margin-left: 15px;
      }
      > * {
         &:not(:last-child) {
            margin-right: 35px;
         }
      }
   }
}

.cs_site_header_full_width .container {
   max-width: 100%;
   padding: 0 100px;
}

.cs_site_header_style1 {
   border-bottom: 1px solid #1a188d;

   .cs_main_header_right {
      display: flex;
      align-items: center;
   }

   .cs_nav + .cs_header_toolbox.cs_center {
      margin-left: 35px;
   }

   .cs_nav .cs_nav_list > li.current-menu-item > a:before {
      bottom: -4px;
      background-color: $white;
   }
}
.cs_sidenav {
   position: fixed;
   z-index: 1111;
   width: 100%;
   height: 100vh;
   display: flex;
   justify-content: flex-end;
   transition: all 0.4s ease;
   opacity: 0;
   visibility: hidden;
   &.active {
      opacity: 1;
      visibility: visible;
   }
}

.cs_sidenav_overlay {
   position: fixed;
   top: 0;
   left: 0;
   background-color: rgba(11, 11, 11, 0.5);
   height: 100%;
   width: 100%;
   cursor: zoom-out;
}

.cs_sidenav_in {
   position: relative;
   z-index: 2;
   background-color: #fff;
   width: 100%;
   max-width: 620px;
   padding: 60px 80px;
   overflow-y: auto;
   overflow-x: hidden;
   hr {
      width: calc(100% + 80px);
   }
   .cs_newsletter.cs_style1 {
      .cs_newsletter_title {
         font-size: 28px;
         line-height: 1.35em;
         margin-bottom: 9px;
      }
   }
   .cs_social_links a {
      background-color: $primary;
      color: #fff;
      height: 30px;
      width: 30px;
      font-size: 14px;
      &:hover {
         background-color: $accent;
      }
   }
   .cs_close {
      margin-bottom: 52px;
   }
}
.cs_close {
   border: none;
   outline: none;
   background-color: transparent;
   cursor: pointer;
   transition: all 0.4s ease;
   &:hover {
      transform: rotate(90deg);
   }
}
.cs_header_search {
   position: fixed;
   top: 0;
   left: 0;
   width: 100%;
   z-index: 999;
   opacity: 0;
   visibility: hidden;
   transition: all 0.4s ease;
   &.active {
      opacity: 1;
      visibility: visible;
   }
}
.cs_header_search_in {
   position: relative;
   z-index: 1;
   background-color: #fff;
   padding: 50px 0;
   .cs_search_form {
      max-width: 100%;
      input {
         border-radius: 40px;
         padding: 10px 90px 10px 35px;
         font-size: 20px;
         height: 80px;
         @media (max-width: 575px) {
            padding: 10px 65px 10px 20px;
            font-size: 18px;
            height: 66px;
         }
      }
      .cs_search_btn {
         width: 80px;
         @media (max-width: 575px) {
            width: 60px;
         }
         svg {
            width: 28px;
            height: 28px;
            @media (max-width: 575px) {
               width: 24px;
               height: 24px;
            }
         }
      }
   }
}
.cs_header_search_box {
   display: flex;
   align-items: center;
   gap: 0 30px;
   @media (max-width: 991px) {
      gap: 0 15px;
   }
}
@media screen and (max-width: 1199px) {
   .cs_main_header .container {
      max-width: 100%;
   }

   .cs_site_header.cs_style1 {
      .cs_nav {
         display: flex;
      }
   }
}

.cs_nav ul {
   margin: 0;
   padding: 0;
   list-style: none;
}

.cs_sticky_header {
   position: fixed !important;
   width: 100%;
   z-index: 999;
}
.cs_gescout_sticky {
   position: fixed !important;
   top: -110px;
   opacity: 0;
   visibility: hidden;
   transition: all 0.4s ease;
   background-color: #fff;
   .cs_main_header_in {
      height: 90px;
   }
   &.cs_white_color {
      background-color: $primary;
   }
}

.cs_gescout_show {
   top: 0 !important;
   opacity: 1;
   visibility: visible;
   box-shadow: 0px 1px 2px 0px rgba(2, 0, 181, 0.1);
}

.cs_site_branding {
   display: inline-block;
   img {
      height: 70px !important;
   }
}

@media screen and (min-width: 1200px) {
   .cs_site_header.cs_style1.cs_white_color {
      .menu-item-has-children {
         ul {
            color: $primary;
         }
      }
   }
   .cs_main_header {
      position: relative;

      .container-fluid {
         padding-right: 40px;
         padding-left: 40px;
      }
   }

   .cs_main_header_center,
   .cs_top_header_center {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
   }

   .cs_site_header.cs_style1 {
      .cs_main_header_center {
         display: flex;
         justify-content: center;
         align-items: center;
         height: 100%;
         width: 100%;
         max-width: calc(100% - 300px);
      }
      .cs_main_header_left {
         height: 100%;
         display: flex;
         justify-content: center;
         align-items: center;
         gap: 20px 124px;
      }
   }

   .cs_nav {
      display: flex;
      align-items: center;
      height: 100%;
      line-height: 1.6em;

      .cs_nav_list {
         display: flex !important;
         flex-wrap: wrap;
         height: inherit;

         > li {
            margin-right: 50px;
            height: inherit;

            &:last-child {
               margin-right: 0;
            }

            > a {
               padding: 10px 0;
               display: inline-flex;
               position: relative;
               height: inherit;
               align-items: center;
            }

            > ul {
               left: 0;
               top: calc(100% + 15px);
            }

            &:hover {
               > ul {
                  top: 100%;
                  opacity: 1;
                  visibility: visible;
                  transition: all 0.4s ease;
               }
            }
            &.menu-item-has-children {
               > a {
                  position: relative;
                  &::after {
                     content: "";
                     display: inline-block;
                     height: 6px;
                     width: 6px;
                     border: 2px solid currentColor;
                     transform: rotate(45deg);
                     border-left: 0;
                     border-top: 0;
                     margin-left: 6px;
                     position: relative;
                     top: -1px;
                     border-radius: 0px 0px 2px 0px;
                  }
               }
            }
         }

         li:not(.cs_mega_menu) {
            position: relative;
         }

         ul {
            width: 260px;
            background-color: #fff;
            position: absolute;
            box-shadow: 0px 1px 2px 0px rgba(2, 0, 181, 0.1);
            padding: 10px 0;
            z-index: 100;
            opacity: 0;
            visibility: hidden;
            display: block !important;
            border-radius: 15px;
            box-shadow: 0px 4px 21px 1px rgba(48, 123, 196, 0.1);
            transition: all 0.1s ease;

            li {
               &:hover {
                  ul {
                     top: 0px;
                  }

                  > ul {
                     opacity: 1;
                     visibility: visible;
                     transition: all 0.4s ease;
                  }
               }
            }

            a {
               display: block;
               line-height: inherit;
               padding: 10px 20px;
            }

            ul {
               top: 15px;
               left: 100%;
            }
         }
      }
   }
   .cs_menu_toggle,
   .cs_munu_dropdown_toggle {
      display: none;
   }

   .cs_nav .cs_nav_list {
      .cs_mega_menu {
         position: relative;
      }
      .cs_mega_wrapper {
         width: 475px !important;
         left: 0;
         display: flex !important;
         position: absolute;
         padding: 5px 15px 10px;

         a {
            padding: 7px 10px;
         }

         > li {
            flex: 1;
            padding: 10px 0;

            > a {
               font-size: 18px;
               font-weight: 600;
               pointer-events: none;
               &:hover {
                  background-color: transparent;
               }
            }

            ul {
               position: initial;
               border: none;
               padding: 0;
               width: 100%;
               box-shadow: none;
               background-color: transparent;
               a {
                  transition: all 0.4s ease;
                  &:hover {
                     letter-spacing: 1px;
                  }
               }
            }
         }
      }

      .cs_mega_menu {
         &:hover {
            .cs_mega_wrapper {
               li ul {
                  opacity: 1;
                  visibility: visible;
               }
            }
         }
      }
   }

   .cs_nav
      .cs_nav_list
      > li
      ul:not(.cs_mega_wrapper)
      .menu-item-has-children
      > a {
      position: relative;
   }
}

@media screen and (max-width: 1400px) {
   .cs_nav .cs_nav_list .cs_mega_wrapper {
      width: 1116px !important;
   }

   .cs_site-branding {
      max-width: 190px;
   }

   .cs_site_header_full_width .container {
      max-width: 100%;
   }
}

@media screen and (max-width: 1199px) {
   .cs_site_header.cs_style1.cs_mobile_toggle_active {
      background-color: #fff;
      &.cs_white_color {
         background-color: $primary;
      }
   }
   .cs_toolbox .cs_sidebar_toggle_btn {
      display: none;
   }
   .cs_site_header.cs_style1 .cs_main_header_right {
      padding-right: 50px;
   }
   .cs_toolbox .cs_toolbox_btn {
      max-width: 26px;
   }
   .cs_nav .cs_nav_list .cs_mega_wrapper {
      width: 100% !important;
   }
   .cs_site_header_full_width .container {
      padding: 0 15px;
   }
   .cs_munu_dropdown_toggle {
      position: absolute;
      height: 40px;
      width: 100%;
      top: 0;
      left: 0px;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      padding: 28px 18px;
      cursor: pointer;
      z-index: 3;

      span {
         display: block;
         position: relative;
         height: 10px;
         width: 10px;
         line-height: 1.2em;
         &:before,
         &:after {
            content: "";
            display: block;
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            height: 2px;
            width: 10px;
            background-color: currentColor;
            transition: all 0.3s ease;
         }

         &:before {
            transform: translate(-50%, -50%) rotate(90deg);
         }
      }

      &.active {
         span {
            &:before {
               transform: translate(-50%, -50%) rotate(0deg);
            }
         }
      }
   }
   .menu-item-has-children {
      .menu-item-has-children {
         .cs_munu_dropdown_toggle {
            padding: 20px 18px;
         }
      }
   }
   .cs_site_branding {
      position: relative;
      z-index: 101;
   }
   .cs_nav {
      .cs_nav_list {
         position: fixed;
         width: 100vw;
         left: -100vw;
         background-color: #fff;
         color: $primary;
         padding: 10px 0;
         top: 80px;
         overflow: auto;
         height: calc(100vh - 80px);
         line-height: 1.6em;
         transition: all 0.4s ease;
         &.cs_active {
            left: 0vw;
         }

         ul {
            padding-left: 15px;
            display: none;
         }

         a {
            display: block;
            padding: 12px 15px;
            line-height: 16px;
         }
         > li {
            > a {
               font-size: 18px;
               line-height: 1.4em;
            }
         }
      }

      .menu-item-has-children {
         position: relative;
      }
   }

   /*Mobile Menu Button*/
   .cs_menu_toggle {
      display: inline-block;
      width: 30px;
      height: 27px;
      cursor: pointer;
      position: absolute;
      top: 27px;
      right: 30px;
      color: $primary;

      span,
      span:before,
      span:after {
         width: 100%;
         height: 3px;
         background-color: currentColor;
         display: block;
         border-radius: 5px;
      }

      span {
         margin: 0 auto;
         position: relative;
         top: 12px;
         transition-duration: 0s;
         transition-delay: 0.2s;

         &:before {
            content: "";
            position: absolute;
            margin-top: -9px;
            transition-property: margin, transform;
            transition-duration: 0.2s;
            transition-delay: 0.2s, 0s;
         }

         &:after {
            content: "";
            position: absolute;
            margin-top: 9px;
            transition-property: margin, transform;
            transition-duration: 0.2s;
            transition-delay: 0.2s, 0s;
         }
      }
   }

   .cs_site_header.cs_style1 .cs_menu_toggle {
      top: 50%;
      right: 0px;
      margin-top: -13px;
   }

   .cs_toggle_active {
      span {
         background-color: rgba(0, 0, 0, 0);
         transition-delay: 0.2s;

         &:before {
            margin-top: 0;
            transform: rotate(45deg);
            transition-delay: 0s, 0.2s;
         }

         &:after {
            margin-top: 0;
            transform: rotate(-45deg);
            transition-delay: 0s, 0.2s;
         }
      }
   }

   .cs_nav .cs_nav_list a {
      position: relative;
   }

   .cs_site_header.cs_style1 .cs_main_header_in {
      height: 80px;
   }

   .cs_site_header .current-menu-item > a:before {
      display: none;
   }
   .cs_site_header.cs_style1 .cs_main_header_center {
      .cs_site_branding {
         position: absolute;
         left: 0px;
         top: 50%;
         transform: translateY(-50%);
      }
   }
   .cs_site_header.cs_style1 {
      top: 0;
   }

   .cs_has_main_nav {
      display: none;
   }
}

@media screen and (max-width: 991px) {
   .cs_site_header .container {
      max-width: 100%;
   }
   .cs_site_header.cs_style1 {
      .cs_action_box > *:not(:last-child) {
         margin-right: 25px;
      }
   }
}
@media screen and (max-width: 575px) {
   .cs_site-branding {
      max-width: 150px;
   }
}
/*Start Demo Menu*/
@media (min-width: 1200px) {
   .cs_nav .cs_nav_list > li > ul.cs_demo_mega {
      width: 1110px;
      display: flex !important;
      text-align: center;
      padding: 15px;
      left: 50%;
      transform: translateX(-50%);
      position: fixed;
      top: 100px;
      a {
         padding: 10px;
      }
      img {
         border-radius: 8px;
         box-shadow: 0px 3px 18px 1px rgba(48, 123, 196, 0.1);
         border: 1px solid rgba($primary, 0.06);
      }
      span {
         display: block;
         // font-weight: 500;
         margin-top: 6px;
         font-size: 90%;
         margin-bottom: -8px;
      }
      + ul {
         display: none !important;
      }
   }
}
@media (max-width: 1199px) {
   .cs_nav .cs_nav_list > li > ul.cs_demo_mega {
      display: none !important;
   }
}
/*End Demo Menu*/
.cs_site_header.cs_style1 {
   &.cs_color_2 {
      background-color: #fff;
   }
   .cs_btn.cs_style_1 {
      @media (max-width: 420px) {
         padding: 7px 15px;
         font-size: 14px;
         i {
            display: none;
         }
      }
      @media (max-width: 350px) {
         display: none;
      }
   }
}
